
import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getSearchStayList, getStayList } from '@/api/stay';
import { addFavoriteItem, deleteFavoriteItem } from '@/api/favoriteItem';
import { UserModule } from '@/store/user';
import { getSearchStoreInfoList } from '@/api/storeInfo';
import { getIndustryList } from '@/api/industry';
import { getOptionItemList } from '@/api/optionItem';
import Calendar from '@/components/Calendar/index.vue';
import AiSearchList from './components/aiSearchList.vue';

@Component({
  components: {
    Calendar,
    AiSearchList,
  },
})

export default class extends Vue {
  mounted() {
    this.getIndustryList();
    this.getRegionList();
    this.init();
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private searchedIndustryIdx: any = null;

  private listQuery: any = {
    searchValue: this.$route.query.searchValue,
    industryIdx: this.$route.query.industryIdx,
    startDate: this.$route.query.startDate,
    endDate: this.$route.query.endDate,
    region: this.$route.query.region,
    lat: this.$route.query.lat,
    lng: this.$route.query.lng,
    adult: this.$route.query.adult,
    child: this.$route.query.child,
    page: 0,
    size: 4,
  }

  private centerDialogVisible= false;

  private slotVisible= false;

  private slotVisibleDefault = true;

  private slotVisibleAi= false;

  private stayList = [];

  private stayListCount = 0;

  private regionList = [];

  private mainItem = {
    restaurantList: [],
    restaurantListCount: 0,
    placeList: [],
    placeListCount: 0,
    leisureList: [],
    leisureListCount: 0,
    excitingList: [],
    excitingListCount: 0,
    productList: [],
    productListCount: 0,
  }

  private industryList = [];

  private removeNoScr() {
    // 모달 닫을 때 <html> 스크롤 되도록
    document.documentElement.classList.remove('noscr');
  }

  private selectedPersonnel() {
    this.slotVisible = !this.slotVisible;
  }

  private getIndustryList() {
    getIndustryList().then((res) => {
      this.industryList = res.data;
    });
  }

  private getRegionList() {
    getOptionItemList('REGION').then((res) => {
      this.regionList = res.data;
    });
  }

  private getSearchStayList() {
    getSearchStayList(this.listQuery).then((res) => {
      this.stayList = res.data.stayList;
      this.stayListCount = res.data.count;
      this.stayloading = false;
    });
  }

  private favoriteFlag = false;

  private async handleAddFavoriteStay(event: Event, idx: any, type: number) {
    if (this.favoriteFlag) {
      this.$message.info('저장중입니다.');
      return;
    }
    this.favoriteFlag = true;
    if (UserModule.token) {
      const target = (event.target as HTMLElement)?.parentElement?.parentElement?.parentElement?.parentElement;
      if (target?.classList.contains('active')) {
        if (target.getAttribute('data-id')) {
          await deleteFavoriteItem(target.getAttribute('data-id')).then(() => {
            this.$message.success('삭제되었습니다.');
            target.classList.remove('active');
            target.removeAttribute('data-id');
          });
        } else {
          this.$message.info('해당 서비스를 찾을 수 없습니다.');
        }
      } else if (target && !(target?.classList.contains('active'))) {
        let query = {};
        if (type === 1) query = { stayIdx: idx };
        else if (type === 2) query = { storeInfoIdx: idx };
        await addFavoriteItem(query).then((res) => {
          this.$message.success('저장되었습니다.');
          target.classList.add('active');
          target.setAttribute('data-id', res.data);
        });
      }
    } else {
      this.$message.info('로그인이 필요한 서비스입니다.');
    }
    this.favoriteFlag = false;
  }

  private getSearchStoreInfoList() {
    getSearchStoreInfoList({ ...this.listQuery, useDate: this.listQuery.startDate }).then((res) => {
      this.mainItem = {
        ...this.mainItem,
        ...res.data,
      };
      this.loading = false;
    });
  }

  private getPickerOptions() {
    const options = {
      disabledDate(time: any) {
        return moment(time).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
      },
    };
    return options;
  }

  private handleTotalSearch() {
    this.$router.push({ name: 'TotalSearch', query: { ...this.listQuery } }).then(() => {
      this.init();
    }).catch(() => {
      this.centerDialogVisible = false;
      this.init();
    });
  }

  private handleSearchDialogVisible() {
    this.centerDialogVisible = !this.centerDialogVisible;
    // 모달 열 때 <html> 스크롤 안되도록
    document.documentElement.classList.add('noscr');

    setTimeout(() => {
      const calendarRef: any = this.$refs.calendar;
      if (calendarRef) calendarRef.setDate(new Date(this.listQuery.startDate), new Date(this.listQuery.endDate));
      this.calendarData = [];
      const target = (this.$refs.searchBox as HTMLElement);
      target.focus();
    }, 100);
  }

  private handleDefaultSearch() {
    this.slotVisibleDefault = true;
    this.slotVisibleAi = false;
  }

  private handleAisearch() {
    if (!this.centerDialogVisible) this.centerDialogVisible = true;
    this.slotVisibleDefault = false;
    this.slotVisibleAi = true;
  }

  private focusInput() {
    this.$nextTick(() => {
      (this.$refs.searchInput as any).$el.querySelector('input').focus();
    });
  }

  private async init() {
    await this.getSearchStayList();
    await this.getSearchStoreInfoList();
    this.searchedIndustryIdx = this.$route.query.industryIdx;
  }

  private truncateName(name: string, maxLength: number) :string {
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...`;
    }
    return name;
  }

  private searchInput = '';

  private loading = true;

  private stayloading = true;

  private calendarLoading = false;

  private calendarData = []; // 달력 일별 데이터

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.calendarData = [];
  }

  private handleChoiceDate(startDate: string, endDate: string) {
    this.selectedDay = startDate;
    this.listQuery.startDate = startDate;
    this.listQuery.endDate = endDate;
  }

  private handleResetFormQuery() {
    this.listQuery = {
      searchValue: '',
      industryIdx: null,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      adult: 1,
      child: 0,
      page: 0,
      size: 4,
      region: '',
      lat: this.listQuery.lat,
      lng: this.listQuery.lng,
    };
    const calendarRef: any = this.$refs.calendar;
    if (calendarRef) calendarRef.removeActiveClass();
  }

  private stayNavigateToDetail(stayIdx: string) {
    this.$router.push({ name: 'StayDetail', params: { stayIdx }, query: { ...this.$route.query } });
  }

  private restaurantNavigateToDetail(storeIdx: string) {
    this.$router.push({ name: 'RestaurantView', params: { idx: storeIdx } });
  }

  private hotplaceNavigateToDetail(storeIdx: string) {
    this.$router.push({ name: 'PlaceView', params: { idx: storeIdx } });
  }

  private leisureNavigateToDetail(storeIdx: string) {
    this.$router.push({ name: 'LeisureView', params: { idx: storeIdx }, query: { ...this.$route.query } });
  }

  private experiencNavigateToDetail(storeIdx: string) {
    this.$router.push({ name: 'PlaceView', params: { idx: storeIdx } });
  }
}
